<template>
  <el-dialog
    :append-to-body="true"
    :visible.sync="dialog"
    :title="isAdd ? '新增' : '编辑'"
    width="500px"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      label-width="120px"
    >
      <el-form-item label="部门名称" prop="name">
        <el-input v-model="form.name" style="width: 300px;" maxlength="20" show-word-limit />
      </el-form-item>
      <el-form-item label="部门英文名称" prop="nameEn">
        <el-input
          v-model="form.nameEn"
          style="width: 300px;"
          maxlength="20"
          show-word-limit
          @keyup.native="keydowHandle"
        />
      </el-form-item>
      <el-form-item label="部门说明" >
        <el-input
          type="textarea"
          v-model="form.desc"
          rows="5"
          style="width: 300px;"
          maxlength="50"
          show-word-limit
          placeholder="请输入(选填)"
        />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { limitEnAndSpace } from '@/utils/validate'
import { addPartment, editPartment } from '@/api/ib/tManager'
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    divisionInfo: {
      type: Object,
      default(){
        return {
          name: '',
          nameEn: '',
          desc: '',
        }
      }
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      form: {
        name: '',
        nameEn: '',
        desc: '',
      },
      rules:{
        name: [
          { required: true, message: '请输入部门名称', trigger: 'blur' }
        ],
        nameEn: [
          { required: true, message: '请输入部门英文名称', trigger: 'blur' }
        ]
      }
    }
  },
  watch:{
    divisionInfo:{
      handler(newValue,oldValue){
        this.form = JSON.parse(JSON.stringify(newValue));
      },
      deep: true
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    keydowHandle(){
      this.form.nameEn = limitEnAndSpace(this.form.nameEn);
    },
    doSubmit() {
      this.$refs.form.validate((valid) => {
        if(valid){
          this.loading = true
          if (this.isAdd) {
            this.doAdd()
          } else this.doEdit()
        }else {
          console.log('validate no pass');
        }
      })
    },
    refreshList(){
      this.$emit('refreshList');
    },
    doAdd() {
      addPartment(this.form).then(res => {

        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.refreshList();
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      editPartment({
        id: this.form.id,
        desc: this.form.desc,
        name: this.form.name,
        nameEn: this.form.nameEn,
      }).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.refreshList();
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        name: '',
        nameEn: '',
        desc: '',
      }
    }
  }
}
</script>

<style scoped>

</style>
