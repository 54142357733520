<template>
  <div class="app-container">
    <el-button
      size="small"
      type="primary"
      icon="el-icon-plus"
      @click="addDivisionHandle"
    >部门管理</el-button>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="编号"/>
      <el-table-column prop="name" label="部门名称"/>
      <el-table-column prop="nameEn" label="部门英文名称"/>
      <el-table-column prop="desc" label="部门说明"/>
      <el-table-column label="操作" width="150px" align="center">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="success"
            @click="editHandle(scope.row)"
          >编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <division-management-add-or-edit
      ref="divisiondailog"
      :division-info="currentDivisionInfo"
      @refreshList="refreshHandle"
      :is-add="isAdd"
    ></division-management-add-or-edit>
  </div>
</template>

<script>
import divisionManagementAddOrEdit from '@/components/manager/divisionManagement/form'
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { parseTime } from '@/utils/index'
export default {
  name:'managerlst',
  components: {
    divisionManagementAddOrEdit
  },
  mixins: [initData],
  data() {
    return {
      currentDivisionInfo:{
        name: '',
        nameEn: '',
        desc: ''
      },
      isAdd: false,
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = 'crm/tDepartment'
      const sort = 'id,desc'
      this.params = {
        page: this.page,
        size: this.size,
        sort: sort
      }
      return true
    },
    addDivisionHandle(){
      this.isAdd = true;
      this.resetCurrentDivision();
      this.$refs.divisiondailog.dialog = true;
    },
    resetCurrentDivision(){
      this.currentDivisionInfo = {
          name: '',
          nameEn: '',
          desc: ''
      }
    },
    editHandle(row){
      this.isAdd = false;
      this.currentDivisionInfo = row;
      this.$refs.divisiondailog.dialog = true;
    },
    refreshHandle(){
      this.init();
    }
  }
}
</script>

<style scoped>

</style>
